import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClipboardText = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.1 3.1v1.8h5.8V3.1H9.1m7.998-.2A2.1 2.1 0 0 0 15 .9H9a2.1 2.1 0 0 0-2.098 2H6A3.1 3.1 0 0 0 2.9 6v14A3.1 3.1 0 0 0 6 23.1h12a3.1 3.1 0 0 0 3.1-3.1V6A3.1 3.1 0 0 0 18 2.9h-.902m0 2.2A2.1 2.1 0 0 1 15 7.1H9a2.1 2.1 0 0 1-2.098-2H6a.9.9 0 0 0-.9.9v14a.9.9 0 0 0 .9.9h12a.9.9 0 0 0 .9-.9V6a.9.9 0 0 0-.9-.9h-.902M6.9 11A1.1 1.1 0 0 1 8 9.9h.01a1.1 1.1 0 1 1 0 2.2H8A1.1 1.1 0 0 1 6.9 11m4 0A1.1 1.1 0 0 1 12 9.9h4a1.1 1.1 0 1 1 0 2.2h-4a1.1 1.1 0 0 1-1.1-1.1m-4 5A1.1 1.1 0 0 1 8 14.9h.01a1.1 1.1 0 0 1 0 2.2H8A1.1 1.1 0 0 1 6.9 16m4 0a1.1 1.1 0 0 1 1.1-1.1h4a1.1 1.1 0 0 1 0 2.2h-4a1.1 1.1 0 0 1-1.1-1.1"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgClipboardText
