import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSnowflake = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 10 10" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M5 0c.26 0 .469.209.469.469v.9l.449-.45a.469.469 0 0 1 .662.662L5.467 2.696V4.19l1.293-.746.408-1.52a.47.47 0 0 1 .906.243l-.164.611.725-.418a.47.47 0 0 1 .468.813l-.724.418.615.164a.47.47 0 0 1-.242.906l-1.52-.408L5.937 5l1.293.746 1.52-.408a.47.47 0 0 1 .242.906l-.613.166.724.418a.47.47 0 0 1-.468.813l-.725-.418.164.615a.47.47 0 0 1-.906.242L6.76 6.56l-1.291-.747v1.494L6.582 8.42a.469.469 0 0 1-.662.662l-.45-.45v.9a.468.468 0 1 1-.937 0V8.63l-.449.45a.469.469 0 0 1-.662-.662l1.113-1.114V5.813l-1.293.746-.408 1.52a.47.47 0 0 1-.906-.243l.162-.613-.725.418a.47.47 0 0 1-.469-.813l.725-.418-.615-.164a.47.47 0 0 1 .242-.906l1.52.408L4.062 5 2.77 4.254l-1.521.406a.47.47 0 0 1-.242-.906l.615-.164-.725-.418a.47.47 0 0 1 .47-.813l.724.418-.164-.615a.47.47 0 0 1 .906-.242l.408 1.52 1.291.748V2.692L3.418 1.582A.469.469 0 0 1 4.08.92l.45.45V.468c0-.26.208-.469.468-.469H5Z"
    />
  </svg>
)
export default SvgSnowflake
