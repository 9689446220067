import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}
const SvgAccounts = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.5 9.5H12l-1.5 2.25h-3L6 9.5H1.5"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m1.5 9.5 2.588-5.168A1.5 1.5 0 0 1 5.43 3.5h7.14a1.5 1.5 0 0 1 1.342.832L16.5 9.5V14a1.5 1.5 0 0 1-1.5 1.5H3A1.5 1.5 0 0 1 1.5 14V9.5Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgAccounts
