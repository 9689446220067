import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgJollyroger = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 10 10" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M6.29 3.629c.381-.301.585-.71.585-1.13C6.875 1.74 6.142.938 5 .938c-1.143 0-1.875.801-1.875 1.563 0 .42.203.828.584 1.129a.94.94 0 0 1 .353.734v.168c0 .086.07.156.156.156h1.563c.086 0 .156-.07.156-.156v-.168c0-.285.13-.556.354-.734Zm.585.902c0 .604-.49 1.094-1.094 1.094H4.218c-.603 0-1.093-.49-1.093-1.094v-.168c-.577-.457-.938-1.123-.938-1.863C2.187 1.12 3.447 0 5 0c1.552 0 2.812 1.12 2.812 2.5 0 .74-.361 1.406-.937 1.863v.168Zm-6.198.72a.469.469 0 0 1 .633-.198L5 6.973l3.689-1.918a.47.47 0 0 1 .434.832L6.014 7.5l3.107 1.615a.469.469 0 1 1-.433.832l-3.69-1.92-3.689 1.92a.47.47 0 0 1-.633-.2.471.471 0 0 1 .2-.632L3.984 7.5.877 5.885a.47.47 0 0 1-.2-.633ZM3.75 2.657a.469.469 0 1 1 .937 0 .469.469 0 0 1-.937 0Zm2.03-.469a.469.469 0 1 1 0 .938.469.469 0 0 1 0-.938Z"
    />
  </svg>
)
export default SvgJollyroger
