import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUpload = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.481.481a.733.733 0 0 1 1.038 0l3.333 3.334a.733.733 0 1 1-1.037 1.037L7.733 2.77V9a.733.733 0 1 1-1.466 0V2.77L4.185 4.852a.733.733 0 1 1-1.037-1.037L6.481.48ZM1 8.267c.405 0 .733.328.733.733v2.667a.6.6 0 0 0 .6.6h9.334a.6.6 0 0 0 .6-.6V9a.733.733 0 1 1 1.466 0v2.667a2.066 2.066 0 0 1-2.066 2.066H2.333a2.067 2.067 0 0 1-2.066-2.066V9c0-.405.328-.733.733-.733"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgUpload
