import { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDashboard = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 6.125v-1.5a1.875 1.875 0 0 0-3.75 0v1.5a1.875 1.875 0 0 0 3.75 0ZM13.875 2.75h-3a1.875 1.875 0 0 0 0 3.75h3a1.875 1.875 0 0 0 0-3.75Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 9.5H12.5a2 2 0 0 0-2 2v2.75a2 2 0 0 0 2 2h1.25a2 2 0 0 0 2-2V11.5a2 2 0 0 0-2-2ZM5.5 11H4.25a2 2 0 0 0-2 2v1.25a2 2 0 0 0 2 2H5.5a2 2 0 0 0 2-2V13a2 2 0 0 0-2-2Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgDashboard
