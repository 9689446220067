import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHamburger = ({ title, titleId, width = '1em', height = '1em', ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.9 6A1.1 1.1 0 0 1 3 4.9h18a1.1 1.1 0 0 1 0 2.2H3A1.1 1.1 0 0 1 1.9 6m0 6A1.1 1.1 0 0 1 3 10.9h18a1.1 1.1 0 0 1 0 2.2H3A1.1 1.1 0 0 1 1.9 12m0 6A1.1 1.1 0 0 1 3 16.9h18a1.1 1.1 0 0 1 0 2.2H3A1.1 1.1 0 0 1 1.9 18"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgHamburger
