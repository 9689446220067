import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgChevronRight = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 17 16" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.148 3.481a.733.733 0 0 1 1.037 0l4 4a.733.733 0 0 1 0 1.038l-4 4a.733.733 0 0 1-1.037-1.037L9.63 8 6.148 4.519a.733.733 0 0 1 0-1.038"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgChevronRight
