import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCoffee = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 10 10" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M1.719 0c.26 0 .468.209.468.469 0 .314.147.463.465.74l.022.02c.306.267.764.667.764 1.427a.468.468 0 1 1-.938 0c0-.314-.146-.463-.465-.74l-.021-.02C1.707 1.63 1.25 1.23 1.25.47c0-.26.209-.469.469-.469ZM.937 8.125c0 .518.42.938.938.938h3.75c.518 0 .938-.42.938-.938V4.687H.938v3.438ZM0 4.375c0-.346.28-.625.625-.625h7.188a2.186 2.186 0 1 1 0 4.375H7.5C7.5 9.16 6.66 10 5.625 10h-3.75A1.875 1.875 0 0 1 0 8.125v-3.75Zm7.5 2.813h.313a1.251 1.251 0 0 0 0-2.5H7.5v2.5ZM4.375.468c0 .315.146.464.465.741l.021.02c.307.267.764.667.764 1.427a.468.468 0 1 1-.938 0c0-.314-.146-.463-.464-.74l-.022-.02C3.895 1.63 3.438 1.23 3.438.47a.468.468 0 1 1 .937 0Z"
    />
  </svg>
)
export default SvgCoffee
