import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUsers = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 4.1a2.9 2.9 0 1 0 0 5.8 2.9 2.9 0 0 0 0-5.8M3.9 7a5.1 5.1 0 1 1 10.2 0A5.1 5.1 0 0 1 3.9 7m11.034-4.143a1.1 1.1 0 0 1 1.339-.793 5.1 5.1 0 0 1 0 9.881 1.1 1.1 0 1 1-.546-2.13 2.9 2.9 0 0 0 0-5.62 1.1 1.1 0 0 1-.793-1.338ZM2.394 15.394A5.1 5.1 0 0 1 6 13.9h6a5.1 5.1 0 0 1 5.1 5.1v2a1.1 1.1 0 0 1-2.2 0v-2a2.9 2.9 0 0 0-2.9-2.9H6A2.9 2.9 0 0 0 3.1 19v2a1.1 1.1 0 0 1-2.2 0v-2a5.1 5.1 0 0 1 1.494-3.606Zm15.541-.54a1.1 1.1 0 0 1 1.34-.79A5.1 5.1 0 0 1 23.1 19v2a1.1 1.1 0 0 1-2.2 0v-1.998a2.9 2.9 0 0 0-2.175-2.806 1.1 1.1 0 0 1-.79-1.34"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgUsers
