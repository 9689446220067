import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBoxShippingDelivery = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
      d="M15.75 6v6a1.501 1.501 0 0 1-.75 1.297l-5.25 3a1.5 1.5 0 0 1-1.5 0l-5.25-3A1.5 1.5 0 0 1 2.25 12V6A1.5 1.5 0 0 1 3 4.702l5.25-3a1.5 1.5 0 0 1 1.5 0l5.25 3A1.5 1.5 0 0 1 15.75 6Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
      d="M2.452 5.22 9 9.007l6.547-3.787M6 6.75 12 3M9 16.56V9"
    />
  </svg>
)
export default SvgBoxShippingDelivery
