import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPhoneOutgoing = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M14.9 2A1.1 1.1 0 0 0 16 3.1h3.344l-4.122 4.122a1.1 1.1 0 1 0 1.556 1.556L20.9 4.656V8a1.1 1.1 0 0 0 2.2 0V2A1.1 1.1 0 0 0 22 .9h-6A1.1 1.1 0 0 0 14.9 2"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.473.97c-1.249.256-2.304 1.377-2.458 2.61-.089.71.226 2.656.693 4.283 1.422 4.951 4.7 9.305 9.11 12.097 1.037.656 3.135 1.666 4.258 2.05 1.748.598 3.879 1.056 4.942 1.063 1.319.01 2.331-.655 2.906-1.906.179-.389.185-.473.185-2.69v-1.783c0-.334-.074-.664-.216-.966-.4-.854-1.224-1.546-2.074-1.74-.198-.046-.66-.14-1.027-.21a17.023 17.023 0 0 1-1.567-.412c-.803-.254-.974-.281-1.59-.254-.877.04-1.404.283-2.169 1.005l-.55.518-.263-.182c-1.326-.918-2.872-2.425-3.738-3.645l-.503-.707.593-.62c.744-.78.958-1.27.966-2.21.004-.534-.045-.793-.291-1.535-.163-.49-.369-1.3-.457-1.799-.2-1.136-.434-1.625-1.051-2.196C8.366.995 8.128.937 5.798.914 4.716.904 3.67.93 3.473.97m4.093 2.187c.287.145.447.535.638 1.554.092.492.275 1.215.406 1.607.258.769.291 1.066.15 1.34-.05.096-.444.538-.877.98-.442.452-.812.904-.843 1.028-.09.355.01.645.481 1.407 1.266 2.047 3.029 3.847 5.127 5.238 1.506.999 1.632.988 2.912-.25l.067-.065c.52-.503.76-.734 1.033-.787.233-.045.49.04.967.196l.072.023c.413.135 1.148.32 1.634.41 1.132.212 1.312.283 1.495.584.137.223.151.43.152 2.123v1.785c0 .06-.008.12-.024.177l-.006.02a.624.624 0 0 1-.65.454l-.509-.04c-.34-.025-1.07-.142-1.622-.259-3.502-.738-6.932-2.584-9.476-5.097-2.857-2.823-4.845-6.718-5.436-10.646-.16-1.06-.115-1.397.22-1.68a.875.875 0 0 1 .565-.205l1.502.001c1.302 0 1.878.03 2.022.102"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgPhoneOutgoing
