import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPhoneMissed = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.172 3.833h-6.01M14.167.828v6.01M15.583 15.11v-2.125a1.417 1.417 0 0 0-1.218-1.438 9.097 9.097 0 0 1-1.99-.496 1.416 1.416 0 0 0-1.495.319l-.9.9a11.334 11.334 0 0 1-4.25-4.25l.9-.9a1.417 1.417 0 0 0 .319-1.495 9.095 9.095 0 0 1-.496-1.99 1.417 1.417 0 0 0-1.417-1.218H2.911a1.417 1.417 0 0 0-1.41 1.544c.233 2.19.978 4.293 2.175 6.141a13.813 13.813 0 0 0 4.25 4.25 14.019 14.019 0 0 0 6.113 2.175 1.418 1.418 0 0 0 1.544-1.417Z"
    />
  </svg>
)
export default SvgPhoneMissed
