import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWarningCircle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#warning-circle_svg__a)">
      <path
        fill="#7E1218"
        fillRule="evenodd"
        d="M9 2.325a6.675 6.675 0 1 0 0 13.35 6.675 6.675 0 0 0 0-13.35ZM.675 9a8.325 8.325 0 1 1 16.65 0A8.325 8.325 0 0 1 .675 9M9 5.175c.456 0 .825.37.825.825v3a.825.825 0 0 1-1.65 0V6c0-.456.37-.825.825-.825M8.175 12c0-.456.37-.825.825-.825h.008a.825.825 0 0 1 0 1.65H9A.825.825 0 0 1 8.175 12"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="warning-circle_svg__a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgWarningCircle
