import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTag = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
      d="m10.065 15.435 5.378-5.378a1.5 1.5 0 0 0 0-2.115L9 1.5H1.5V9l6.442 6.435a1.5 1.5 0 0 0 2.123 0ZM5.25 5.25h.008"
    />
  </svg>
)
export default SvgTag
