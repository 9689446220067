import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 17" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M11.022 5.85 8.833 1.418 6.645 5.85l-4.895.715 3.542 3.45-.836 4.873 4.377-2.302 4.378 2.302-.836-4.873 3.542-3.45-4.895-.715Z"
    />
  </svg>
)
export default SvgStar
