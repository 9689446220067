import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTruck = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.6} clipPath="url(#truck_svg__a)">
      <path d="M12 2.25H.75V12H12V2.25ZM15 6h-3v6h5.25V8.25L15 6ZM4.125 15.75a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM13.875 15.75a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z" />
    </g>
    <defs>
      <clipPath id="truck_svg__a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgTruck
