import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPieChart = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#pie-chart_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.481.815A.733.733 0 0 1 8 .6 7.4 7.4 0 0 1 15.4 8a.733.733 0 0 1-.733.733H8A.733.733 0 0 1 7.267 8V1.333c0-.194.077-.38.214-.518m1.252 1.297v5.155h5.155a5.93 5.93 0 0 0-5.155-5.155m-2.728-.519a.733.733 0 0 1-.378.966 5.933 5.933 0 1 0 7.837 7.749.733.733 0 1 1 1.351.571A7.4 7.4 0 1 1 5.04 1.215a.733.733 0 0 1 .965.378"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="pie-chart_svg__a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgPieChart
