import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFire = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 10 10" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M2.37 3.053A9.84 9.84 0 0 1 3.954.984c.365.366.799.922 1.174 1.4.074.095.144.186.213.272a.47.47 0 0 0 .742-.008c.064-.084.15-.21.24-.34l.153-.218c.109-.154.205-.283.28-.373.391.406.802 1.035 1.122 1.726.346.746.559 1.504.559 2.077 0 2.011-1.54 3.543-3.438 3.543-1.92 0-3.438-1.532-3.438-3.543 0-.733.317-1.61.807-2.465v-.002ZM4.52.227A.817.817 0 0 0 3.395.225a10.583 10.583 0 0 0-1.838 2.363C1.029 3.506.625 4.55.625 5.52.625 8.025 2.541 10 5 10c2.434 0 4.375-1.97 4.375-4.48 0-.764-.271-1.665-.646-2.471-.375-.81-.881-1.586-1.405-2.092A.818.818 0 0 0 6.186.955c-.155.149-.332.39-.475.592l-.022.031C5.324 1.113 4.891.576 4.521.227Zm.512 7.898c.494 0 .932-.137 1.344-.41.822-.574 1.043-1.723.549-2.625-.088-.176-.313-.188-.44-.04l-.492.573a.313.313 0 0 1-.482-.01L4.285 4.055a.307.307 0 0 0-.482-.002c-.66.83-.992 1.353-.992 1.941.002 1.338.99 2.131 2.222 2.131Z"
    />
  </svg>
)
export default SvgFire
