import { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSortDesc = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M.442 10.182V8.904l5.081-7.13H.433V0h7.795v1.278L3.142 8.407h5.096v1.775H.442ZM2.307 23.182H0L3.515 13h2.774L9.8 23.182H7.492l-2.55-7.855h-.08l-2.555 7.855Zm-.144-4.002h5.449v1.68h-5.45v-1.68Z"
      fill="currentColor"
    />
    <path
      d="m17.667 1.182.125 21m0 0 6.125-7m-6.125 7-6.125-7"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgSortDesc
