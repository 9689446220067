import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCalendarClock = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.333.6c.405 0 .734.328.734.733v.6h3.866v-.6a.733.733 0 0 1 1.467 0v.6h1.267A2.067 2.067 0 0 1 14.733 4v1a.733.733 0 0 1-1.466 0V4a.6.6 0 0 0-.6-.6H11.4V4a.733.733 0 1 1-1.467 0v-.6H6.067V4A.733.733 0 1 1 4.6 4v-.6H3.333a.6.6 0 0 0-.6.6v1.933h2.6a.733.733 0 0 1 0 1.467h-2.6v5.933a.6.6 0 0 0 .6.6h2.334a.733.733 0 0 1 0 1.467H3.333a2.067 2.067 0 0 1-2.066-2.067V4a2.067 2.067 0 0 1 2.066-2.067H4.6v-.6C4.6.928 4.928.6 5.333.6m5.334 6.8a3.267 3.267 0 1 0 0 6.533 3.267 3.267 0 0 0 0-6.533M7.32 7.32a4.733 4.733 0 1 1 6.694 6.694A4.733 4.733 0 0 1 7.32 7.32m3.347 1.28c.405 0 .733.328.733.733v1.157l.736.613a.733.733 0 0 1-.939 1.127l-1-.833a.733.733 0 0 1-.264-.564v-1.5c0-.405.329-.733.734-.733"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCalendarClock
