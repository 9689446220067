import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMegaphone = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.666 5.124A1.1 1.1 0 0 1 22.1 6v12a1.1 1.1 0 0 1-1.339 1.074l-8.151-1.812a4.108 4.108 0 0 1-7.986-1.774l-1.863-.414A1.1 1.1 0 0 1 1.9 14v-3c0-.494.33-.928.806-1.06l18-5a1.1 1.1 0 0 1 .96.184M6.792 15.97a1.909 1.909 0 0 0 3.65.81l-3.65-.81ZM4.1 11.836v1.282l15.8 3.51v-9.18L4.1 11.835Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgMegaphone
