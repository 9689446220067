import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgScanText = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.333 2.733a.6.6 0 0 0-.6.6v1.334a.733.733 0 0 1-1.466 0V3.333a2.067 2.067 0 0 1 2.066-2.066h1.334a.733.733 0 0 1 0 1.466H3.333M10.6 2c0-.405.328-.733.733-.733h1.334a2.067 2.067 0 0 1 2.066 2.066v1.334a.733.733 0 1 1-1.466 0V3.333a.6.6 0 0 0-.6-.6h-1.334A.733.733 0 0 1 10.6 2M3.933 5.333c0-.405.329-.733.734-.733H10a.733.733 0 0 1 0 1.467H4.667a.733.733 0 0 1-.734-.734Zm0 2.667c0-.405.329-.733.734-.733h6.666a.733.733 0 0 1 0 1.466H4.667A.733.733 0 0 1 3.933 8m0 2.667c0-.405.329-.734.734-.734h4a.733.733 0 0 1 0 1.467h-4a.733.733 0 0 1-.734-.733M2 10.6c.405 0 .733.328.733.733v1.334a.6.6 0 0 0 .6.6h1.334a.733.733 0 1 1 0 1.466H3.333a2.067 2.067 0 0 1-2.066-2.066v-1.334c0-.405.328-.733.733-.733m12 0c.405 0 .733.328.733.733v1.334a2.066 2.066 0 0 1-2.066 2.066h-1.334a.733.733 0 1 1 0-1.466h1.334a.6.6 0 0 0 .6-.6v-1.334c0-.405.328-.733.733-.733"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgScanText
