import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPhoneCall = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 19" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#phone-call_svg__a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
        d="M11.288 4.25a3.75 3.75 0 0 1 2.962 2.963M11.288 1.25a6.75 6.75 0 0 1 5.962 5.955m-.75 5.985v2.25a1.502 1.502 0 0 1-1.635 1.5 14.843 14.843 0 0 1-6.472-2.303 14.625 14.625 0 0 1-4.5-4.5A14.843 14.843 0 0 1 1.59 3.635 1.5 1.5 0 0 1 3.083 2h2.25a1.5 1.5 0 0 1 1.5 1.29 9.63 9.63 0 0 0 .525 2.107A1.5 1.5 0 0 1 7.02 6.98l-.952.952a12 12 0 0 0 4.5 4.5l.952-.952a1.5 1.5 0 0 1 1.583-.338c.68.254 1.387.43 2.107.526a1.5 1.5 0 0 1 1.29 1.522Z"
      />
    </g>
    <defs>
      <clipPath id="phone-call_svg__a">
        <path fill="#fff" d="M0 .5h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgPhoneCall
