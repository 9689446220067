import { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRadio = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={10} cy={10} r={6} fill="var(--radio-inner)" />
    <path
      d="M10 19a9 9 0 0 1-9-9h-2c0 6.075 4.925 11 11 11v-2Zm9-9a9 9 0 0 1-9 9v2c6.075 0 11-4.925 11-11h-2Zm-9-9a9 9 0 0 1 9 9h2c0-6.075-4.925-11-11-11v2Zm0-2C3.925-1-1 3.925-1 10h2a9 9 0 0 1 9-9v-2Z"
      fill="var(--radio-border)"
    />
  </svg>
)
export default SvgRadio
