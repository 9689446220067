import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCircleDashedCheckStatus = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#circle-dashed-check-status_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.874 2.173a5.887 5.887 0 0 1 2.252 0 .733.733 0 0 0 .281-1.44 7.353 7.353 0 0 0-2.814 0 .733.733 0 1 0 .28 1.44m-2.192.91a.733.733 0 0 0-.817-1.219 7.367 7.367 0 0 0-2 1.992.733.733 0 0 0 1.216.821 5.9 5.9 0 0 1 1.6-1.595m7.462-1.217a.733.733 0 0 0-.821 1.215c.63.426 1.171.97 1.595 1.6a.733.733 0 0 0 1.218-.816 7.367 7.367 0 0 0-1.992-2m-9.97 5.008a.733.733 0 0 0-1.44-.281 7.353 7.353 0 0 0 0 2.814.733.733 0 1 0 1.44-.28 5.887 5.887 0 0 1 0-2.253m13.092-.281a.733.733 0 1 0-1.44.28 5.887 5.887 0 0 1 0 2.253.733.733 0 1 0 1.44.28 7.351 7.351 0 0 0 0-2.813M3.082 11.318a.733.733 0 1 0-1.218.817 7.368 7.368 0 0 0 1.992 2 .733.733 0 0 0 .821-1.216 5.9 5.9 0 0 1-1.595-1.6Zm11.052.826a.733.733 0 0 0-1.215-.822 5.901 5.901 0 0 1-1.6 1.596.733.733 0 1 0 .816 1.218 7.367 7.367 0 0 0 2-1.992m-7.26 1.683a.733.733 0 1 0-.28 1.44c.928.18 1.884.18 2.813 0a.733.733 0 0 0-.28-1.44 5.897 5.897 0 0 1-2.253 0m3.711-6.575a.733.733 0 1 0-1.037-1.037L7.4 8.363l-.815-.815a.733.733 0 0 0-1.037 1.037l1.333 1.333a.733.733 0 0 0 1.037 0l2.667-2.666"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="circle-dashed-check-status_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgCircleDashedCheckStatus
