import { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgContacts = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.75 16.25v-1.5a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v1.5M7.75 8.75a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM18.25 16.25v-1.5A3 3 0 0 0 16 11.847M13 2.847a3 3 0 0 1 0 5.813"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgContacts
