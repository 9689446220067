import { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCheckbox = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M0 6a6 6 0 0 1 6-6h8a6 6 0 0 1 6 6v8a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z"
      fill="var(--checkbox-background)"
    />
    <path
      d="M6 1h8v-2H6v2Zm13 5v8h2V6h-2Zm-5 13H6v2h8v-2ZM1 14V6h-2v8h2Zm5 5a5 5 0 0 1-5-5h-2a7 7 0 0 0 7 7v-2Zm13-5a5 5 0 0 1-5 5v2a7 7 0 0 0 7-7h-2ZM14 1a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2ZM6-1a7 7 0 0 0-7 7h2a5 5 0 0 1 5-5v-2Z"
      fill="var(--checkbox-border-color)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.716 5.77c.26.26.26.682 0 .943l-7.333 7.333a.667.667 0 0 1-.943 0l-3.334-3.333a.667.667 0 1 1 .943-.943l2.862 2.862 6.862-6.862c.26-.26.683-.26.943 0Z"
      fill="var(--checkbox-color)"
    />
  </svg>
)
export default SvgCheckbox
