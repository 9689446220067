import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTrash2 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.485 1.985c-.129.129-.177.265-.177.348v.525h3.384v-.525c0-.083-.048-.22-.177-.348-.129-.129-.265-.177-.348-.177H5.833c-.083 0-.22.048-.348.177m4.49.873v-.525c0-.5-.244-.947-.553-1.255-.308-.31-.755-.553-1.255-.553H5.833c-.5 0-.947.244-1.255.553-.31.308-.553.755-.553 1.255v.525H1.75a.642.642 0 1 0 0 1.284h.525v7.525c0 .5.244.947.553 1.255.308.31.755.553 1.255.553h5.834c.5 0 .947-.244 1.255-.553.31-.308.553-.755.553-1.255V4.142h.525a.642.642 0 1 0 0-1.284H9.975M3.558 4.142v7.525c0 .083.048.22.177.348.129.129.265.177.348.177h5.834c.083 0 .22-.048.348-.177.129-.129.177-.265.177-.348V4.142H3.558m2.275 1.633c.355 0 .642.287.642.642v3.5a.642.642 0 1 1-1.283 0v-3.5c0-.355.287-.642.641-.642m2.334 0c.354 0 .641.287.641.642v3.5a.642.642 0 1 1-1.283 0v-3.5c0-.355.287-.642.642-.642"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgTrash2
