import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFsg = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="-8 0 60 22" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={22} cy={11} r={30} fill="#fff" />
    <circle cx={22} cy={11} r={29.5} stroke="currentColor" />
    <path
      fill="currentColor"
      d="M6.91 0v6.875H0C0 3.081 3.096 0 6.91 0ZM6.91 7.563H0v6.875h6.91V7.561ZM6.91 15.125H0V22h6.91v-6.875ZM7.602 0h6.91c0 3.794-3.097 6.875-6.91 6.875V0ZM7.602 7.563h6.91c0 3.794-3.097 6.875-6.91 6.875V7.561ZM22.112 0v6.875h-6.91C15.202 3.081 18.3 0 22.112 0ZM15.202 7.563h6.91v6.875c-3.813 0-6.91-3.081-6.91-6.876ZM22.112 15.125V22h-6.91c0-3.794 3.097-6.875 6.91-6.875ZM22.804 0h6.91c0 3.794-3.096 6.875-6.91 6.875V0ZM22.804 7.563c3.814 0 6.91 3.08 6.91 6.875h-6.91V7.561ZM22.804 15.125h6.91c0 3.794-3.096 6.875-6.91 6.875v-6.875ZM37.316 0v6.875h-6.91c0-3.794 3.096-6.875 6.91-6.875ZM37.316 7.563h-6.91v6.875h6.91V7.561ZM30.405 15.125h6.91V22c-3.813 0-6.91-3.08-6.91-6.875ZM38.007 0h6.91c0 3.794-3.096 6.875-6.91 6.875V0ZM44.917 11h-6.91v3.438h6.91V11ZM44.917 15.125h-6.91V22h6.91v-6.875Z"
    />
  </svg>
)
export default SvgFsg