import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRecent = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 12 12" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#12161D"
      fillRule="evenodd"
      d="M5.998.108H6A5.892 5.892 0 1 1 .108 6a.642.642 0 0 1 1.284 0 4.608 4.608 0 1 0 4.61-4.608 5.046 5.046 0 0 0-3.484 1.414l-.219.219h1.368a.642.642 0 1 1 0 1.283H.75a.642.642 0 0 1-.642-.641V.75a.642.642 0 1 1 1.284 0v1.368l.223-.223.007-.008A6.33 6.33 0 0 1 5.998.108ZM6 2.442c.354 0 .642.287.642.641v2.52l1.978.99a.642.642 0 0 1-.574 1.148L5.713 6.574A.642.642 0 0 1 5.358 6V3.083c0-.354.288-.641.642-.641"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgRecent
