import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLockKeyhole = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.067a2.6 2.6 0 0 0-2.6 2.6v1.266h5.2V4.667a2.6 2.6 0 0 0-2.6-2.6m4.067 3.866V4.667a4.067 4.067 0 0 0-8.134 0v1.266h-.6A2.067 2.067 0 0 0 1.267 8v5.333c0 1.142.925 2.067 2.066 2.067h9.334a2.067 2.067 0 0 0 2.066-2.067V8a2.067 2.067 0 0 0-2.066-2.067h-.6M3.333 7.4a.6.6 0 0 0-.6.6v5.333a.6.6 0 0 0 .6.6h9.334a.6.6 0 0 0 .6-.6V8a.6.6 0 0 0-.6-.6H3.333M8 10.733a.067.067 0 1 0 0-.133.067.067 0 0 0 0 .133m-1.4-.066a1.4 1.4 0 1 1 2.8 0 1.4 1.4 0 0 1-2.8 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgLockKeyhole
