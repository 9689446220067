import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCheck = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.573 3.616a.533.533 0 0 1 0 .754l-5.867 5.867a.533.533 0 0 1-.754 0L2.285 7.57a.533.533 0 0 1 .754-.754l2.29 2.29 5.49-5.49a.533.533 0 0 1 .754 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCheck
