import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCamera = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.77 2.197a.733.733 0 0 1 .563-.264h3.334c.217 0 .424.097.563.264l1.447 1.736h1.656A2.067 2.067 0 0 1 15.4 6v6a2.067 2.067 0 0 1-2.067 2.067H2.667A2.067 2.067 0 0 1 .6 12V6a2.067 2.067 0 0 1 2.067-2.067h1.656L5.77 2.197M6.677 3.4 5.23 5.136a.733.733 0 0 1-.563.264h-2a.6.6 0 0 0-.6.6v6a.6.6 0 0 0 .6.6h10.666a.6.6 0 0 0 .6-.6V6a.6.6 0 0 0-.6-.6h-2a.734.734 0 0 1-.563-.264L9.323 3.4H6.677M8 7.4a1.267 1.267 0 1 0 0 2.533A1.267 1.267 0 0 0 8 7.4M5.267 8.667a2.733 2.733 0 1 1 5.466 0 2.733 2.733 0 0 1-5.466 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCamera
