import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGlobe = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#globe_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.112 7.267h2.514a10.4 10.4 0 0 1 1.664-4.95 5.94 5.94 0 0 0-4.178 4.95ZM8 2.45a8.934 8.934 0 0 0-1.903 4.817h3.806A8.934 8.934 0 0 0 8 2.45m1.903 6.283H6.097A8.934 8.934 0 0 0 8 13.55a8.934 8.934 0 0 0 1.903-4.817m-3.613 4.95a10.4 10.4 0 0 1-1.664-4.95H2.112a5.94 5.94 0 0 0 4.178 4.95m3.42 0a10.4 10.4 0 0 0 1.664-4.95h2.515a5.94 5.94 0 0 1-4.18 4.95m4.179-6.416h-2.515a10.4 10.4 0 0 0-1.664-4.95 5.94 5.94 0 0 1 4.179 4.95M.6 8a7.4 7.4 0 1 1 14.8 0A7.4 7.4 0 0 1 .6 8"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="globe_svg__a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgGlobe
