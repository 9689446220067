import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCircleDollarSign = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#circle-dollar-sign_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3.1a8.9 8.9 0 1 0 0 17.8 8.9 8.9 0 0 0 0-17.8ZM.9 12C.9 5.87 5.87.9 12 .9S23.1 5.87 23.1 12 18.13 23.1 12 23.1.9 18.13.9 12M12 4.9A1.1 1.1 0 0 1 13.1 6v.9H16a1.1 1.1 0 0 1 0 2.2h-2.9v1.8h.9a3.1 3.1 0 1 1 0 6.2h-.9v.9a1.1 1.1 0 0 1-2.2 0v-.9H8a1.1 1.1 0 0 1 0-2.2h2.9v-1.8H10a3.1 3.1 0 0 1 0-6.2h.9V6A1.1 1.1 0 0 1 12 4.9m-1.1 4.2H10a.9.9 0 0 0 0 1.8h.9V9.1m2.2 4v1.8h.9a.9.9 0 1 0 0-1.8z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="circle-dollar-sign_svg__a">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgCircleDollarSign
