import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}
const SvgSite = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.567 1.808a.525.525 0 0 0-.373.155l-.001.002-1.477 1.477h8.568L9.807 1.965l-.001-.002a.525.525 0 0 0-.373-.155H4.567m7.625 2.917H1.808v1.108a.525.525 0 0 0 .509.525.933.933 0 0 0 .53-.216 1.05 1.05 0 0 1 1.305 0 .933.933 0 0 0 .515.215.933.933 0 0 0 .514-.215 1.05 1.05 0 0 1 1.305 0A.933.933 0 0 0 7 6.357a.933.933 0 0 0 .514-.215 1.05 1.05 0 0 1 1.305 0 .933.933 0 0 0 .514.215.933.933 0 0 0 .515-.215 1.05 1.05 0 0 1 1.305 0c.15.126.335.201.53.216a.525.525 0 0 0 .509-.525V4.725m-1.167 2.796a2.218 2.218 0 0 1-.525-.265 2.216 2.216 0 0 1-1.131.385.643.643 0 0 1-.07 0 2.217 2.217 0 0 1-1.132-.385 2.217 2.217 0 0 1-1.132.385.643.643 0 0 1-.07 0 2.217 2.217 0 0 1-1.132-.385 2.217 2.217 0 0 1-1.131.385.643.643 0 0 1-.07 0A2.217 2.217 0 0 1 3.5 7.256c-.163.111-.34.2-.525.265v4.146a.525.525 0 0 0 .525.525h1.108V10.5a1.808 1.808 0 0 1 1.809-1.808h1.166A1.808 1.808 0 0 1 9.392 10.5v1.692H10.5a.525.525 0 0 0 .525-.525V7.52m-2.917 4.67V10.5a.525.525 0 0 0-.525-.525H6.417a.525.525 0 0 0-.525.525v1.692h2.216M5.25 13.476h5.25a1.808 1.808 0 0 0 1.808-1.808V7.524a1.808 1.808 0 0 0 1.167-1.69v-1.75a.642.642 0 0 0-.188-.454l-2.571-2.572A1.808 1.808 0 0 0 9.432.525H4.568m.682 12.95H3.5a1.808 1.808 0 0 1-1.808-1.808V7.524a1.808 1.808 0 0 1-1.167-1.69v-1.75c0-.17.068-.334.188-.454l2.571-2.572A1.808 1.808 0 0 1 4.569.525"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSite
