import NextAuth from 'next-auth'
import callbacks from './callbacks'

export interface ProviderOptions {
  baseUrl: string
  clientId: string
}

export function FSG(opts: ProviderOptions) {
  return NextAuth({
    providers: [
      {
        id: 'fsg',
        name: 'FSG',
        type: 'oauth',
        checks: ['state', 'pkce'],
        clientId: opts.clientId,
        client: {
          token_endpoint_auth_method: 'none',
        },
        authorization: `${opts.baseUrl}/authorize`,
        token: `${opts.baseUrl}/token`,
        userinfo: `${opts.baseUrl}/me`,
        profile: (user) => user,
      },
    ],
    callbacks: callbacks(opts),
  })
}
