import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOfficeBuilding = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 3.1a.9.9 0 0 0-.9.9v16a.9.9 0 0 0 .9.9h1.9V18A1.1 1.1 0 0 1 9 16.9h6a1.1 1.1 0 0 1 1.1 1.1v2.9H18a.9.9 0 0 0 .9-.9V4a.9.9 0 0 0-.9-.9H6Zm12 20a3.1 3.1 0 0 0 3.1-3.1V4A3.1 3.1 0 0 0 18 .9H6A3.1 3.1 0 0 0 2.9 4v16A3.1 3.1 0 0 0 6 23.1zm-4.1-2.2v-1.8h-3.8v1.8zM6.9 6A1.1 1.1 0 0 1 8 4.9h.01a1.1 1.1 0 1 1 0 2.2H8A1.1 1.1 0 0 1 6.9 6Zm4 0A1.1 1.1 0 0 1 12 4.9h.01a1.1 1.1 0 0 1 0 2.2H12A1.1 1.1 0 0 1 10.9 6m4 0A1.1 1.1 0 0 1 16 4.9h.01a1.1 1.1 0 0 1 0 2.2H16A1.1 1.1 0 0 1 14.9 6m-8 4A1.1 1.1 0 0 1 8 8.9h.01a1.1 1.1 0 1 1 0 2.2H8A1.1 1.1 0 0 1 6.9 10m4 0A1.1 1.1 0 0 1 12 8.9h.01a1.1 1.1 0 0 1 0 2.2H12a1.1 1.1 0 0 1-1.1-1.1m4 0A1.1 1.1 0 0 1 16 8.9h.01a1.1 1.1 0 0 1 0 2.2H16a1.1 1.1 0 0 1-1.1-1.1m-8 4A1.1 1.1 0 0 1 8 12.9h.01a1.1 1.1 0 0 1 0 2.2H8A1.1 1.1 0 0 1 6.9 14m4 0a1.1 1.1 0 0 1 1.1-1.1h.01a1.1 1.1 0 0 1 0 2.2H12a1.1 1.1 0 0 1-1.1-1.1m4 0a1.1 1.1 0 0 1 1.1-1.1h.01a1.1 1.1 0 0 1 0 2.2H16a1.1 1.1 0 0 1-1.1-1.1"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgOfficeBuilding
