'use client'
import { useCallback, useEffect, useState } from 'react'
import { signOut, useSession } from 'next-auth/react'
import { Status } from './types'
import { Session } from 'next-auth'

interface FetchOptions extends RequestInit {
  url?: string
  path?: string
  auth?: boolean
  refresh?: boolean
  omitContentType?: boolean
  responseHandler?: (response: Response) => Promise<any>
}

// client side version of request helper
export function useRequestOnMount<APIResponseType>(requestOptions: FetchOptions, dataDefaultState: APIResponseType = undefined) {
  const { request } = useRequestCallback()

  const [data, setData] = useState<APIResponseType>(dataDefaultState)
  const [status, setStatus] = useState<Status>('loading')
  const [error, setError] = useState(null)

  useEffect(() => {
    let mounted = true
    const controller = new AbortController()

    const getData = async () => {
      setStatus('loading')
      try {
        const res = await request<APIResponseType>({ ...requestOptions, signal: controller.signal })
        if (!mounted) return
        setData(res)
        setStatus('success')
      } catch (err: any) {
        if (!mounted) return
        setStatus('error')
        setError(error)
      }
    }

    getData()

    return () => {
      mounted = false
      controller.abort()
    }
  }, [])

  return { request, data, status, error }
}

export function useRequestCallback() {
  const { data: session, status: sessionStatus, update: refreshSession } = useSession()

  const request = useCallback(
    async <APIResponseType>({ url, path, auth = true, refresh = false, ...options }: FetchOptions = {}) => {
      if (url && path) {
        throw new Error('URL and Path are mutually exclusive')
      }

      if (!url && !path) {
        throw new Error('Either URL or Path is required')
      }

      let requestUrl = path ? process.env.NEXT_PUBLIC_API_GATEWAY_BASE_URL + path : url

      if (refresh) requestUrl += requestUrl.includes('?') ? '&refresh=1' : '?refresh=1'

      if (requestUrl.includes('localhost') || requestUrl.includes('127.0.0.1')) {
        requestUrl = requestUrl.replace('/engage', '')
      }

      const response = await (async function fetchWithRetry(scopedSession: Session, retry: boolean): Promise<Response> {
        if (auth) {
          if (sessionStatus === 'unauthenticated') {
            signOut()
            throw new Error(`You must be signed in to access this resource (${requestUrl})`)
          }
  
          if (scopedSession.error === 'RefreshAccessTokenError') {
            signOut()
            throw new Error(`Your session has timed out`)
          }
        }

        const res = await fetch(requestUrl, {
          ...options,
          mode: 'cors',
          headers: {
            ...(options.headers || {}),
            ...(auth && scopedSession?.user ? { Authorization: `Bearer ${scopedSession.accessToken}` } : {}),
            ...(options.omitContentType ? {} : {'Content-Type': 'application/json'}),
          },
        })

        // client session thinks it is authenticated but API returned 401, refresh client session and try again
        // note this doesn't actually trigger a token refresh, the token refresh happens independently on the server.
        // This method just requests the latest session from the server.
        if (retry && auth && res.status === 401) {
          return await fetchWithRetry(await refreshSession(), false)
        }

        return res
      })(session, true)

      if (!response.ok) {
        const errorResponse = await response.json()
        if (errorResponse?.responseData) {
          throw new Error(errorResponse.responseData)
        } else if (errorResponse?.message) {
          throw new Error(`Failed to fetch data (${response.status}): ${errorResponse.message}`)
        } else {
          throw new Error(`Failed to fetch data (${response.status})`)
        }
      }

      const data: APIResponseType = options.responseHandler ? await options.responseHandler(response) : await response.json()
      return data
    },
    [session?.accessToken],
  )

  return { request, session, sessionStatus }
}
