import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAlertCircle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 1.808a5.192 5.192 0 1 0 0 10.384A5.192 5.192 0 0 0 7 1.808ZM.525 7a6.475 6.475 0 1 1 12.95 0A6.475 6.475 0 0 1 .525 7Zm5.833-2.333c0-.355.288-.642.642-.642h.006a.642.642 0 0 1 0 1.283H7a.642.642 0 0 1-.642-.641M7 6.358c.354 0 .642.288.642.642v2.333a.642.642 0 1 1-1.284 0V7c0-.354.288-.642.642-.642"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgAlertCircle
