import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMoreHorizontal = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 17 16" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 8.067a.067.067 0 1 0 0-.134.067.067 0 0 0 0 .134M2.6 8a1.4 1.4 0 1 1 2.8 0 1.4 1.4 0 0 1-2.8 0m6.067.067a.067.067 0 1 0 0-.134.067.067 0 0 0 0 .134M7.267 8a1.4 1.4 0 1 1 2.8 0 1.4 1.4 0 0 1-2.8 0m6.066.067a.067.067 0 1 0 0-.134.067.067 0 0 0 0 .134M11.933 8a1.4 1.4 0 1 1 2.8 0 1.4 1.4 0 0 1-2.8 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgMoreHorizontal
