import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPhoneAdd = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.6} clipPath="url(#phone-add_svg__a)">
      <path d="M13.792 1.125v5.833M10.875 4.042h5.833M16.5 14.94v-2.25a1.5 1.5 0 0 0-1.29-1.522 9.631 9.631 0 0 1-2.107-.525 1.5 1.5 0 0 0-1.583.337l-.952.953a12 12 0 0 1-4.5-4.5l.952-.953a1.5 1.5 0 0 0 .338-1.582 9.63 9.63 0 0 1-.525-2.108 1.5 1.5 0 0 0-1.5-1.29h-2.25A1.5 1.5 0 0 0 1.59 3.135a14.843 14.843 0 0 0 2.303 6.503 14.625 14.625 0 0 0 4.5 4.5 14.843 14.843 0 0 0 6.472 2.302 1.5 1.5 0 0 0 1.635-1.5Z" />
    </g>
    <defs>
      <clipPath id="phone-add_svg__a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgPhoneAdd
