import { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSortAsc = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.442 23.182v-1.278l5.081-7.13h-5.09V13h7.795v1.278l-5.086 7.129h5.096v1.775H1.442ZM2.307 10.182H0L3.515 0h2.774L9.8 10.182H7.492l-2.55-7.855h-.08l-2.555 7.855ZM2.163 6.18h5.449v1.68h-5.45V6.18Z"
      fill="currentColor"
    />
    <path
      d="m18 1 .125 21m0 0 6.125-7m-6.125 7L12 15"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgSortAsc
