import { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGridAllApps = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.95 2.7H3.7a1 1 0 0 0-1 1v.25a1 1 0 0 0 1 1h.25a1 1 0 0 0 1-1V3.7a1 1 0 0 0-1-1ZM3.95 7.95H3.7a1 1 0 0 0-1 1v.25a1 1 0 0 0 1 1h.25a1 1 0 0 0 1-1v-.25a1 1 0 0 0-1-1ZM3.95 13.2H3.7a1 1 0 0 0-1 1v.25a1 1 0 0 0 1 1h.25a1 1 0 0 0 1-1v-.25a1 1 0 0 0-1-1ZM9.2 2.7h-.25a1 1 0 0 0-1 1v.25a1 1 0 0 0 1 1h.25a1 1 0 0 0 1-1V3.7a1 1 0 0 0-1-1ZM9.2 7.95h-.25a1 1 0 0 0-1 1v.25a1 1 0 0 0 1 1h.25a1 1 0 0 0 1-1v-.25a1 1 0 0 0-1-1ZM9.2 13.2h-.25a1 1 0 0 0-1 1v.25a1 1 0 0 0 1 1h.25a1 1 0 0 0 1-1v-.25a1 1 0 0 0-1-1ZM14.45 2.7h-.25a1 1 0 0 0-1 1v.25a1 1 0 0 0 1 1h.25a1 1 0 0 0 1-1V3.7a1 1 0 0 0-1-1ZM14.45 7.95h-.25a1 1 0 0 0-1 1v.25a1 1 0 0 0 1 1h.25a1 1 0 0 0 1-1v-.25a1 1 0 0 0-1-1ZM14.45 13.2h-.25a1 1 0 0 0-1 1v.25a1 1 0 0 0 1 1h.25a1 1 0 0 0 1-1v-.25a1 1 0 0 0-1-1Z"
      fill="#12161D"
      stroke="#12161D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgGridAllApps
