import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDollarSign = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.6} clipPath="url(#dollar-sign_svg__a)">
      <path d="M9 .75v16.5M12.75 3.75H7.125a2.625 2.625 0 0 0 0 5.25h3.75a2.625 2.625 0 0 1 0 5.25H4.5" />
    </g>
    <defs>
      <clipPath id="dollar-sign_svg__a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgDollarSign
