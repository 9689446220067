import { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDrilldown = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g
      clipPath="url(#drilldown_svg__a)"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.833 3.417 5 1.333l4.167 2.084L5 5.5.833 3.417ZM.833 7.583 5 9.667l4.167-2.084M.833 5.5 5 7.583 9.167 5.5" />
    </g>
    <defs>
      <clipPath id="drilldown_svg__a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgDrilldown
