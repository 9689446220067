import type { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgXCircle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 19 19" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#x-circle_svg__a)">
      <path strokeWidth={1.8} d="M9.5 17.417a7.917 7.917 0 1 0 0-15.833 7.917 7.917 0 0 0 0 15.833ZM11.875 7.125l-4.75 4.75" />
      <path strokeWidth={2} d="m7.125 7.125 4.75 4.75" />
    </g>
    <defs>
      <clipPath id="x-circle_svg__a">
        <path fill="#fff" d="M0 0h19v19H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgXCircle
