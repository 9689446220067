import { Account, Profile, Session, User } from 'next-auth'
import { JWT } from 'next-auth/jwt'
import memoize from 'memoize'
import { ProviderOptions } from './provider'

export type JwtCallback = (params: { token: JWT; user?: User; account?: Account; profile?: Profile }) => Promise<JWT>

export type SessionCallback = (params: { session: Session; token: JWT }) => Promise<Session>

interface Callbacks {
  jwt: JwtCallback
  session: SessionCallback
}

export default function callbacks(opts: ProviderOptions): Callbacks {
  const jwt: JwtCallback = async ({ token, user, account }): Promise<JWT> => {
    if (user && account) {
      return {
        accessToken: account.access_token,
        refreshToken: account.refresh_token,
        expiresAt: account.expires_at,
        user: {
          id: user.id,
          name: user.name,
          username: user.username,
          email: user.email,
          employeeNumber: user.employee_number,
          division: user.division,
          department: user.department,
          jobTitle: user.job_title,
          jobCode: user.job_code,
          picture: user.picture,
        },
      }
    }

    if (Date.now() < token.expiresAt * 1000) {
      return token
    }

    return await rotateTokens(token, opts)
  }

  const session: SessionCallback = async ({ session, token }): Promise<Session> => {
    session.accessToken = token.accessToken
    session.user = token.user
    session.error = token.error
    return session
  }

  return { jwt, session }
}

// Request a token refresh and cache the promise. This is required to
// prevent multiple requests from trying to refresh the same token which
// will fail after the first refresh. Only need to cache for a short time
// since this problem should only occur on page load
const rotateTokens = memoize(
  async (token: JWT, opts: ProviderOptions): Promise<JWT> => {
    try {
      const response = await fetch(`${opts.baseUrl}/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: opts.clientId,
          refresh_token: token.refreshToken,
        }),
      })

      const tokens = await response.json()
      if (response.ok) {
        return {
          ...token,
          accessToken: tokens.access_token,
          refreshToken: tokens.refresh_token,
          expiresAt: tokens.expires_at,
        }
      } else {
        throw new Error(`Error calling token refresh ${response.status}`)
      }
    } catch (err) {
      console.error('Error refreshing access token', err)
      return { ...token, error: 'RefreshAccessTokenError' }
    }
  },
  {
    cacheKey: (arguments_: any) => arguments_[0]['refreshToken'],
    maxAge: 15000, // cache for 15 seconds
  },
)
